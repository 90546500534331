import React from 'react';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import ErrorIcon from '@material-ui/icons/ErrorOutline';
import DoneIcon from '@material-ui/icons/Done';
import CheckCircle from '@material-ui/icons/CheckCircle';
import ReportProblem from '@material-ui/icons/ReportProblem';
import ArrowForwardFull from '@material-ui/icons/ArrowForward';
import {
    ShowButton, FunctionField, Pagination, translate, Tab, TabbedShowLayout, 
    CardActions, Show, SimpleShowLayout, ReferenceFieldController, List, Datagrid, EditButton, TextField, ReferenceField, DateField
} from 'react-admin';
import { Actions } from '../common/actions'
import { IntegrationFilters } from '../common/filters'
import FullWidthReferenceManyField from '../common/full-width-reference-many-field'
import TranslatedEnumField from '../common/translated-enum-field'
import TextFileViewer from '../common/text-file-viewer'
import { EntityDataLinkButton } from '../common/buttons/EntityDataLinkButtonView';
import { ReturnEntityToSourceButton } from '../common/buttons/ReturnEntityToSourceButtonView';
import { CancelRunButton } from '../common/buttons/CancelRunButtonView';
import { StartRunButton } from '../common/buttons/StartRunButtonView';
import { SetStatusToSucceededButton } from '../common/buttons/SetStatusToSucceededButtonView';
import { ReRunTaskButton } from '../common/buttons/ReRunTaskButtonView';
import { CopyToClipboardButtons } from '../common/buttons/copy-to-clipboard-button';
import { SetEntityStatusToSucceededButton } from '../common/buttons/SetEntityStatusToSucceededButtonView';

export const ToggleRunButton = ({ ...props }) => {
    const record = props.record || props.data
    return record.latestRunIsPastEntrypoint
    ? <StartRunButton record={record} {...props} />
    : <CancelRunButton record={record} {...props} />
};

const StatusField = translate(({ translate, source, record = {} }) => {
    const statusTypeId = record[source];
    return (
        <span title={translate(`enums.statusTypes.${record[source]}`)}>
            {
                statusTypeId >= 129 && statusTypeId < 200 ? <ErrorIcon style={{ color: 'red' }} />
                    : (statusTypeId >= 100 && statusTypeId < 129 ? <ReportProblem style={{ color: 'red' }} />
                        : (statusTypeId === 200 ? <DoneIcon style={{ color: 'green' }} />
                            : (statusTypeId === 201 ? <DoneIcon style={{ color: 'orange' }} />
                                : (statusTypeId === 202 ? <DoneIcon style={{ color: '#04201b' }} />
                                    : (statusTypeId === 203 ? <CheckCircle style={{ color: 'green' }} />
                                        : (statusTypeId === 0 ? <HourglassEmptyIcon />
                                            : <AutorenewIcon />
                                        )
                                    )
                                )
                            )
                        )
                    )

            }</span>
    )
});

export const TaskShow = props => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source="id" />
        </SimpleShowLayout>
    </Show>
);

export const LogShow = props => (
    <Show title={<div />} {...props}>
        <SimpleShowLayout>
            <TextField component="pre" source="exception" />
            <TextField component="pre" source="internalMessage" />
        </SimpleShowLayout>
    </Show>
);

const EntityDataShowActions = ({ basePath, data, resource, record }) => (
    <CardActions>
        <ShowButton icon={<ArrowForwardFull />} label="Go to entity" basePath="/entities" record={{ id: !data ? null : data.integrationEntityId }} />
    </CardActions>
);

export const EntityDataShow = props => (
    <Show actions={<EntityDataShowActions />} {...props}>
        <SimpleShowLayout>
            <CopyToClipboardButtons source="stringFileContent" />
            <TextFileViewer source="stringFileContent" contentTypeSource="contentType" />
        </SimpleShowLayout>
    </Show>
);

const RunDataShowActions = ({ basePath, data, resource, record }) => (
    <CardActions>
        <ShowButton icon={<ArrowForwardFull />} label="Go to run" basePath="/runs" record={{ id: !data ? null : data.integrationRunId }} />
    </CardActions>
);

export const RunDataShow = props => (
    <Show actions={<RunDataShowActions />} {...props}>
        <SimpleShowLayout>
            <TextFileViewer source="stringFileContent" contentTypeSource="contentType" />
        </SimpleShowLayout>
    </Show>
);

const TaskDatagrid = props => (
    <Datagrid {...props}>
        <TextField source="id" />
        <StatusField source="statusTypeId" label="Status" />
        <ReferenceField linkType={false} label="Step" reference="moduleconfigurations" source="integrationModuleConfigurationId">
            <TextField source="name" />
        </ReferenceField>
        <TextField source="message" />
        <FunctionField label="# tries" render={record => record && record.statusTypeIdList && record.statusTypeIdList.length} />
        <DateField showTime={true} source="createdAt" />
        <DateField showTime={true} source="updatedAt" />
        <SetStatusToSucceededButton />
        <ReRunTaskButton />
    </Datagrid>
);

const LogDataGrid = props => (
    <Datagrid {...props} rowClick="expand" expand={<LogShow />}>
        <DateField showTime label="Time" source="timeStamp" />
        <TextField label="Level" source="level" />
        <TextField label="Message" source="message" />
    </Datagrid>
)

const EntityShowActions = ({ basePath, data, resource, record }) => (
    <CardActions>
        <ShowButton icon={<ArrowForwardFull />} label="Go to object" basePath="/objects" record={{ id: !data ? null : data.objectId }} />
        <ShowButton icon={<ArrowForwardFull />} label="Go to run" basePath="/runs" record={{ id: !data ? null : data.integrationRunId }} />
    </CardActions>
);

export const EntityShow = props => (
    <Show actions={<EntityShowActions />} {...props}>
        <TabbedShowLayout>
            <Tab label="tasks">
                <FullWidthReferenceManyField
                    label={null}
                    reference="tasks"
                    target="integrationEntityId"
                    sort={{ field: 'updatedAt', order: 'desc' }}>
                    <TaskDatagrid />
                </FullWidthReferenceManyField>
            </Tab>
            <Tab label="data" path="data">
                <FullWidthReferenceManyField
                    label={null}
                    reference="entitydatas"
                    sort={{ field: 'id', order: 'asc' }}
                    target="integrationEntityId"
                    perPage={100}
                    pagination={<Pagination />}>
                    <Datagrid>
                        <ShowButton />
                        <TextField label="Id" source="id" />
                        <TranslatedEnumField type="dataTypes" source="dataTypeId" />
                        <TextField label="Tracking Id" source="trackingId" />
                        <EntityDataLinkButton />
                    </Datagrid>
                </FullWidthReferenceManyField>
            </Tab>
            <Tab label="metadata" path="metadata">
                <TextFileViewer source="metadata" contentType="application/json" />
            </Tab>
            <Tab label="log" path="log">
                <FullWidthReferenceManyField
                    label={null}
                    reference="logs"
                    target="integrationEntityId"
                    sort={{ field: 'timeStamp', order: 'desc' }}
                    perPage={10}
                    pagination={<Pagination />}>
                    <LogDataGrid />
                </FullWidthReferenceManyField>
            </Tab>
        </TabbedShowLayout>
    </Show>
);

const RunShowActions = ({ basePath, data, resource, record }) => (
    <CardActions>
        <CancelRunButton data={data} record={record} />
        <EditButton icon={<ArrowForwardFull />} label="Go to connection" basePath="/integrationinstances" record={{ id: !data ? null : data.integrationInstanceId }} />
    </CardActions>
);

export const RunShow = props => (
    <Show actions={<RunShowActions />} {...props}>
        <TabbedShowLayout>
            <Tab label="Entities">
                <FullWidthReferenceManyField
                    label="Entities"
                    reference="entities"
                    target="integrationRunId"
                    sort={{ field: 'updatedAt', order: 'desc' }}
                    perPage={10}
                    pagination={<Pagination />}>
                    <Datagrid>
                        <ShowButton />
                        <TextField label="Id" source="id" />
                        <StatusField label="Status" source="statusTypeId" />
                        <TranslatedEnumField label="Type" type="entityTypes" source="entityType" />
                        <TextField label="Tracking Id" source="trackingId" />
                        <TextField source="name" />
                        <DateField showTime={true} source="createdAt" />
                        <DateField showTime={true} source="updatedAt" />
                        <ReturnEntityToSourceButton />
                        <SetEntityStatusToSucceededButton />
                    </Datagrid>
                </FullWidthReferenceManyField>
            </Tab>
            <Tab label="Tasks">
                <FullWidthReferenceManyField
                    label="Tasks"
                    reference="tasks"
                    target="integrationRunId"
                    filter={{ integrationEntityId: 0 }}
                    sort={{ field: 'updatedAt', order: 'desc' }}
                    perPage={10}
                    pagination={<Pagination />}>
                    <TaskDatagrid />
                </FullWidthReferenceManyField>
            </Tab>
            <Tab label="data" path="data">
                <FullWidthReferenceManyField
                    label={null}
                    reference="rundatas"
                    target="integrationRunId">
                    <Datagrid>
                        <ShowButton />
                        <TextField label="Id" source="id" />
                        <TranslatedEnumField type="dataTypes" source="dataTypeId" />
                    </Datagrid>
                </FullWidthReferenceManyField>
            </Tab>
            <Tab label="metadata" path="metadata">
                <TextFileViewer source="metadata" contentType="application/json" />
            </Tab>
            <Tab label="log" path="log">
                <FullWidthReferenceManyField
                    label={null}
                    reference="logs"
                    target="integrationRunId"
                    sort={{ field: 'timeStamp', order: 'desc' }}
                    perPage={10}
                    pagination={<Pagination />}>
                    <LogDataGrid />
                </FullWidthReferenceManyField>
            </Tab>
        </TabbedShowLayout>
    </Show>
);

export const RunList = props => (
    <List actions={<Actions />} sort={{ field: 'createdAt', order: 'desc' }} {...props} filters={<IntegrationFilters showEndpoint={true} showIntegration={true} showHasEntities={true} showModuleConfiguration={true} showStatusTypeId={true} />} title="Runs" exporter={false} bulkActionButtons={false}>
        <Datagrid rowClick="show">
            <ShowButton />
            <StatusField label="Status" source="statusTypeId" />
            <ReferenceFieldController label="Integration" linkType={false} source="integrationInstanceId" reference="integrationinstances">
                {({ referenceRecord }) => (
                    <ReferenceField basePath="/integrationinstances" resource="integrationinstances" reference="integrations" source="integrationId" record={referenceRecord || {}} linkType={false}>
                        <TextField source="name" />
                    </ReferenceField>
                )}
            </ReferenceFieldController>
            <ReferenceFieldController label="Endpoint" linkType={false} source="integrationInstanceId" reference="integrationinstances">
                {({ referenceRecord }) => (
                    <ReferenceField basePath="/integrationinstances" resource="integrationinstances" reference="endpoints" source="endpointId" record={referenceRecord || {}} linkType={false}>
                        <TextField source="endpointName" />
                    </ReferenceField>
                )}
            </ReferenceFieldController>
            <ReferenceField label="Entrypoint" basePath="/moduleconfigurations" reference="moduleconfigurations" source="entrypointModuleConfigurationId" linkType={false}>
                <TextField source="name" />
            </ReferenceField>
            <TextField source="entityCount" label="# entities" />
            <DateField showTime={true} source="createdAt" />
            <DateField showTime={true} source="updatedAt" />
        </Datagrid>
    </List>
);