import React, { Fragment } from 'react';
import { change } from 'redux-form';
import {
    DeleteButton, Pagination, required, REDUX_FORM_NAME, BooleanInput, FormDataConsumer,
    AutocompleteInput, TabbedForm, FormTab, SelectInput, ReferenceInput, NumberInput,
    TextInput, TextField, ReferenceField, DateField, NumberField
} from 'react-admin';
import ConfigInput from '../common/config-input'
import FullWidthReferenceManyField from '../common/full-width-reference-many-field'
import FullWidthDataGrid from '../common/full-width-datagrid';
import TextFileViewer from '../common/text-file-viewer'
import PeriodicityField from '../common/periodicity-field'
import { ToggleRunButton } from '../main-modules/runs'
import TranslatedEnumField from '../common/translated-enum-field'
import { ValidateButton } from '../common/buttons/ValidateButtonView';
import { InstanceMetadataViewer } from '../common/instance-metadata-viewer';
import { Field } from 'redux-form';

export const ToggleValidateButton = ({ record, IsValidatedAfterInstanceCreation, IsValidatedBeforeInstanceCreation, formData, ...props }) => {
    return <ValidateButton record={record} IsValidatedAfterInstanceCreation={IsValidatedAfterInstanceCreation}
        IsValidatedBeforeInstanceCreation={IsValidatedBeforeInstanceCreation} formData={formData} {...props} />
};

const hidden = {
    display: 'none',
};
const HiddenInput = ({ source }) => (
    <Field component="input" type="hidden" name={source} />
);


class InstanceForm extends React.Component {
    render() {
        const { isEditing = false, permissions, LandingForm, CustomToolbar, WebhookAddress, ...props } = this.props
        return (
            permissions == null ? null :
                permissions.group.groupValue <= 100
                    ?
                    <LandingForm {...props} permissions={permissions} />
                    :
                    <TabbedForm {...props} defaultValue={{ entreDataSource: 1 }} toolbar={<CustomToolbar />}>
                        <FormTab label="general">
                            {!isEditing && <ReferenceInput filterToQuery={searchText => ({ name: { searchText: searchText, operation: "lk" } })} source="integrationId" perPage={100} reference="integrations" validate={required()}>
                                <AutocompleteInput allowEmpty={true} translateChoice={false} optionText="name" />
                            </ReferenceInput>}
                            {<FormDataConsumer>
                                {({ formData }) =>
                                    <ReferenceInput source="configurationId" reference="integrationconfigurations" filter={{ integrationId: formData.integrationId }}>
                                        <HiddenInput />
                                    </ReferenceInput>}
                            </FormDataConsumer>}
                            {!isEditing && <FormDataConsumer>
                                {({ formData, dispatch, ...rest }) =>
                                    <Fragment>
                                        <ReferenceInput sort={{ field: 'companyName', order: 'ASC' }} onChange={value => dispatch(
                                            change(REDUX_FORM_NAME, 'endpointId', null)
                                        )} {...rest} allowEmpty={true} source="companyId" label="Company (Customer number)" reference="companies" validate={required()}>
                                            <AutocompleteInput optionText={company => company.companyName === "" ? "" : company.companyName + " (" + company.customerNumber + ")"} />
                                        </ReferenceInput>
                                        {formData.companyId && <ReferenceInput sort={{ field: 'subdivisionName', order: 'ASC' }}
                                            key={`companies-${formData.companyId}`} {...rest} filter={!formData.companyId ? {} : { companyId: formData.companyId }}
                                            source="subdivisionId" reference="companies/subdivisions">
                                            <AutocompleteInput optionText={subdivision => subdivision.subdivisionName === "" ? "" : subdivision.subdivisionName + " (" + subdivision.subdivisionId + ")"} />
                                        </ReferenceInput>}
                                    </Fragment>
                                }
                            </FormDataConsumer>}
                            {isEditing && <ReferenceField source="integrationId" reference="integrations" linkType={false}><TextField source="name" /></ReferenceField>}
                            {isEditing && <NumberField source="externalCompanyNumber" />}
                            {isEditing && <ReferenceField source="companyId" reference="companies" linkType={false}><TextField source="companyName" /></ReferenceField>}
                            {isEditing && <ReferenceField source="endpointId" reference="endpoints" linkType={false}><TextField source="endpointName" /></ReferenceField>}
                            {isEditing && <NumberField source="subdivisionId" />}
                            {isEditing && <ReferenceField source="systemId" reference="systems" linkType={false}><TextField source="systemName" /></ReferenceField>}
                            {isEditing && <FormDataConsumer>
                                {({ formData }) =>
                                    <form >
                                        <div style={hidden}>
                                            <ReferenceInput source="integrationId" reference="integrations" validate={required()}>
                                                <AutocompleteInput optionText={integration => integration.isValidatedAfterInstanceCreation == true
                                                    ? formData.isValidatedAfterInstanceCreation = true : formData.isValidatedAfterInstanceCreation = false} />
                                            </ReferenceInput>
                                        </div>
                                        <ToggleValidateButton record={formData.id} formData={formData} IsValidatedAfterInstanceCreation={formData.isValidatedAfterInstanceCreation}
                                            IsValidatedBeforeInstanceCreation={false} />
                                    </form>
                                }
                            </FormDataConsumer>}
                        </FormTab>
                        <FormTab label="settings">
                            {!isEditing && <FormDataConsumer>
                                {({ formData, ...rest }) =>
                                    <ConfigInput referenceSchema="configurationSchema"
                                        reference="integrationconfigurations"
                                        subdivisionId={formData.subdivisionId}
                                        integrationId={formData.integrationId}
                                        source="properties" context={{ permissions, companyId: formData.companyId, ...rest }} />
                                }
                            </FormDataConsumer>}
                            {isEditing && <FormDataConsumer>
                                {({ formData, ...rest }) =>
                                    <ConfigInput referenceSchema="configurationSchema"
                                        reference="integrationconfigurations"
                                        systemId={permissions.endpoint.systemId}
                                        integrationId={formData.integrationId}
                                        source="properties" context={{ permissions, companyId: formData.companyId, ...rest }} />
                                }
                            </FormDataConsumer>}
                            {isEditing && <FormDataConsumer>
                                {({ formData }) =>
                                    <form >
                                        <div style={hidden}>
                                            <ReferenceInput source="integrationId" reference="integrations" validate={required()}>
                                                <AutocompleteInput optionText={integration => integration.isValidatedBeforeInstanceCreation == true
                                                    ? formData.isValidatedBeforeInstanceCreation = true : formData.isValidatedBeforeInstanceCreation = false} />
                                            </ReferenceInput>
                                        </div>
                                        <ToggleValidateButton record={formData.integrationId} formData={formData} IsValidatedBeforeInstanceCreation={formData.isValidatedBeforeInstanceCreation}
                                            IsValidatedAfterInstanceCreation={false} />
                                    </form>
                                }
                            </FormDataConsumer>}
                        </FormTab>
                        <FormTab label="development">
                            <BooleanInput source="development" />
                            <TextInput source="developmentKey" />
                        </FormTab>
                        {isEditing && <FormTab label="handled">
                            <FullWidthReferenceManyField
                                label="Previously handled"
                                reference="previouslyhandledobjects"
                                source="id"
                                target="integrationInstanceId"
                                sort={{ field: 'createdAt', order: 'desc' }}
                                perPage={10}
                                pagination={<Pagination />}
                            >
                                <FullWidthDataGrid>
                                    <TextField label="Source" source="source" />
                                    <TranslatedEnumField label="Entity type" type="entityTypes" source="entityType" />
                                    <TextField label="Tracking Id" source="trackingId" />
                                    <TextField label="Name" source="name" />
                                    <DateField showTime={true} source="createdAt" />
                                    <DeleteButton redirect={false} />
                                </FullWidthDataGrid>
                            </FullWidthReferenceManyField>
                        </FormTab>}
                        {isEditing && <FormTab label="entrypoints">
                            <FullWidthReferenceManyField
                                label="Entrypoints"
                                reference="entrypoints"
                                source="id"
                                target="integrationInstanceId">
                                <FullWidthDataGrid>
                                    <TextField label="Name" source="integrationModuleConfigurationName" />
                                    <WebhookAddress label="Webhook" source="integrationModuleConfigurationId" />
                                    <DateField showTime={true} source="latestRunCreatedAt" />
                                    <DateField showTime={true} label="Next run" source="runAt" />
                                    <PeriodicityField label="Periodicity" typeSource="periodicityTypeId" numberSource="periodicityNumber" />
                                    <ToggleRunButton />
                                </FullWidthDataGrid>
                            </FullWidthReferenceManyField>
                        </FormTab>}
                        {isEditing && <FormTab label="metadata">
                            <FormDataConsumer>
                                {({ formData, ...rest }) =>
                                    <InstanceMetadataViewer instanceId={formData.id} {...props} />
                                }
                            </FormDataConsumer>
                        </FormTab>}
                        {isEditing && <FormTab label="Entre data">
                            <SelectInput
                                label="Source"
                                fullWidth
                                source="entreDataSource"
                                translateChoice={false}
                                choices={[{ id: 1, name: 'Integrationlog' }, { id: 2, name: 'Integrationsettings' }, { id: 3, name: 'Integrationidmapping' }]}
                            />
                            <FormDataConsumer>
                                {({ formData, ...rest }) =>
                                    <Fragment>
                                        {formData.entreDataSource == 1 && <FullWidthReferenceManyField
                                            {...rest}
                                            label="Entre integration log"
                                            reference="entreintegrationlogs"
                                            source="id"
                                            target="integrationInstanceId"
                                            sort={{ field: 'timestamp', order: 'desc' }}
                                            perPage={10}
                                            pagination={<Pagination />} >
                                            <FullWidthDataGrid>
                                                <TextField label="Integration" source="type" />
                                                <TranslatedEnumField label="Entity type" type="entityTypes" source="entitytype" />
                                                <TextField label="Status" source="status" />
                                                <TextField label="Status type" source="statustype" />
                                                <TextField label="Message" source="message" />
                                                <TextField label="Key" source="key" />
                                                <TextField label="Serie" source="serie" />
                                                <TextField label="Integration entity id" source="integrationentityid" />
                                                <DateField showTime={true} source="timestamp" />
                                                <TextField label="User" source="timeuser" />
                                            </FullWidthDataGrid>
                                        </FullWidthReferenceManyField>}
                                        {formData.entreDataSource == 2 &&
                                            <FullWidthReferenceManyField
                                                {...rest}
                                                label="Entre integration settings"
                                                reference="entreintegrationsettings"
                                                source="id"
                                                target="integrationInstanceId"
                                                sort={{ field: 'timestamp', order: 'desc' }}
                                                perPage={10}
                                                pagination={<Pagination />} >
                                                <FullWidthDataGrid>
                                                    <TextField label="Integration" source="type" />
                                                    <TranslatedEnumField label="Entity type" type="entityTypes" source="entitytype" />
                                                    <TextField label="Integration instance id" source="integrationinstanceid" />
                                                    <DateField showTime={true} source="timestamp" />
                                                    <TextField label="User" source="timeuser" />
                                                </FullWidthDataGrid>
                                            </FullWidthReferenceManyField>
                                        }
                                        {formData.entreDataSource == 3 &&
                                            <FullWidthReferenceManyField
                                                {...rest}
                                                label="Entre id mappings"
                                                reference="entreidmappings"
                                                source="id"
                                                target="integrationInstanceId"
                                                sort={{ field: 'id1', order: 'asc' }}
                                                perPage={10}
                                                pagination={<Pagination />} >
                                                <FullWidthDataGrid>
                                                    <TextField label="Integration" source="Type" />
                                                    <TranslatedEnumField label="Entity type" type="entityTypes" source="EntityType" />
                                                    <TextField label="Internal Id 1" source="Id1" />
                                                    <TextField label="Internal Id 2" source="Id2" />
                                                    <TextField label="External Id 1" source="ExternalId1" />
                                                    <TextField label="External Id 2" source="ExternalId2" />
                                                </FullWidthDataGrid>
                                            </FullWidthReferenceManyField>
                                        }
                                    </Fragment>
                                }
                            </FormDataConsumer>
                        </FormTab>}
                    </TabbedForm>
        )
    }
}

export default InstanceForm;
